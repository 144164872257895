import { Controller } from "@hotwired/stimulus"
const DELAY = 1000
export default class extends Controller {

  connect () {
    const elements = this.element.querySelectorAll("div .highlighter-rouge")
    elements.forEach((element) => {
      const button = document.createElement("button")
      button.classList.add("copy")
      button.innerText = "Copy";
      button.addEventListener("click", async (event) => {
        this.copyToClipboard(element, button)
      })
      element.appendChild(button)
    })
  }

  async copyToClipboard(element, button) {
    let code = element.querySelector("code")
    let text = code.innerText

    await navigator.clipboard.writeText(text)
    button.innerText = "Copied";

    setTimeout(() => {
      button.innerText = "Copy"
    }, DELAY)
  }
}
