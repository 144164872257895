import { Controller } from "@hotwired/stimulus"

const MIN_HEIGHT = 10
export default class extends Controller {
  connect() {
    // console.log(window.scrollY || document.documentElement.scrollTop)
    document.addEventListener('scroll', this.handler.bind(this), false)
  }

  handler(event) {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    if (scrollTop >= MIN_HEIGHT && !this.element.classList.contains("drop-shadow")) {
      this.element.classList.add("drop-shadow")
    } else if (scrollTop < MIN_HEIGHT) {
      this.element.classList.remove("drop-shadow")
    }
  }
}
